export enum ViteEnvironment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}
export enum GhEnvironment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}
export interface AppConfig {
  VITE_AUTH_HOSTNAME: string;
  VITE_AUTH_KEY: string;
  VITE_SERVER_HOSTNAME: string;
  VITE_GH_ENV: GhEnvironment;
  VITE_BASE_PATH: string;
  MODE: ViteEnvironment;
}
export enum AppConfigKeys {
  VITE_AUTH_HOSTNAME = 'VITE_AUTH_HOSTNAME',
  VITE_AUTH_KEY = 'VITE_AUTH_KEY',
  VITE_SERVER_HOSTNAME = 'VITE_SERVER_HOSTNAME',
  VITE_GH_ENV = 'VITE_GH_ENV',
  VITE_BASE_PATH = 'VITE_BASE_PATH'
}

export const environmentVariables: AppConfig = import.meta
  .env as unknown as AppConfig;

import { Button, Input } from '@arco-design/web-react';
import { useState } from 'react';
import { useLoginUserMutation } from '../../communications/api/users.api';

type Props = {};

const Login = (_props: Props) => {
  const [user, setUser] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loggerInUser, setLoggedInUser] = useState<string>('');

  const [loginUser] = useLoginUserMutation();

  const handleLogin = async () => {
    try {
      setLoggedInUser('');
      const result = await loginUser({
        user,
        password
      }).unwrap();
      setLoggedInUser(result.user.email ?? '');
      console.log('Login result', result);
    } catch (error) {
      console.log('Login error', error);
    }
  };

  return (
    <div className="flex flex-col justify-center align-middle">
      <Input
        defaultValue={user}
        onChange={(e) => setUser(e)}
        placeholder="User"
        className={'my-4'}
      />
      <Input
        defaultValue={password}
        onChange={(e) => setPassword(e)}
        placeholder="Password"
        className={'mb-4'}
        type="password"
      />
      <Button type="primary" onClick={() => handleLogin()}>
        Trigger Supabase Login
      </Button>

      <hr className="my-4" />

      <div>
        <div className="my-3">{user || 'empty'}</div>
        <div className="mb-3">{password || 'empty'}</div>
      </div>

      <hr className="my-4" />

      {loggerInUser ? (
        <div>
          <div className="text-green-700 mb-5">Logged In !</div>
          <div>{loggerInUser}</div>
        </div>
      ) : (
        <div className="text-red-700">Not logged in !</div>
      )}
    </div>
  );
};

export default Login;

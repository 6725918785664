import { createClient } from '@supabase/supabase-js';
import { SupabaseAuthClient } from '@supabase/supabase-js/dist/module/lib/SupabaseAuthClient';
import { environmentVariables } from '../constants/environment';
import { SupabaseLoginResult } from '../typings/User.model';

export class AuthService {
  authClient: SupabaseAuthClient;

  constructor() {
    this.authClient = createClient(
      environmentVariables.VITE_AUTH_HOSTNAME,
      environmentVariables.VITE_AUTH_KEY
    ).auth;
  }

  getClient(): SupabaseAuthClient {
    return this.authClient;
  }

  async login(user: string, password: string): Promise<SupabaseLoginResult> {
    const { data, error } = await this.authClient.signInWithPassword({
      email: user,
      password: password
    });
    if (error) {
      throw error;
    }
    return data;
  }
}

import { InputNumber } from '@arco-design/web-react';
import { useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import WebsocketProvider from '../../communications/websocket_providers/WebsocketProvider';
import { environmentVariables } from '../../constants/environment';
import { BASE_WS_PATH } from '../../constants/web';
import { sensorReadingsActions } from '../../redux/slices/sensorReadings.slice';
import { useAppDispatch } from '../../redux/store';
import {
  MockSensorReading,
  MockUserParameters
} from '../../typings/SensorReading.model';

type Props = {};

const DEFAULT_USER_ID = 22;

// * demonstational component - to be removed later
const MockSensorReadings = (_props: Props) => {
  const [userId, setUserId] = useState<number>(DEFAULT_USER_ID);
  const dispatch = useAppDispatch();

  const handleIncomingMessageCallback: (message: MockSensorReading) => void = (
    message
  ) => {
    const extendedMessage = { ...message, id: uuid4() };
    dispatch(sensorReadingsActions.addMockSensorReadings(extendedMessage));
  };

  return (
    <>
      <div>
        <InputNumber defaultValue={userId} onChange={(val) => setUserId(val)} />
      </div>
      <WebsocketProvider<MockUserParameters, MockSensorReading>
        properties={{ id: userId }}
        websocketUrlPath={BASE_WS_PATH}
        serverHostname={environmentVariables.VITE_SERVER_HOSTNAME}
        handleIncomingMessageCallback={handleIncomingMessageCallback}
      />
    </>
  );
};

export default MockSensorReadings;

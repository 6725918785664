import MockSensorReadings from '../../components/MockSensorReadings/MockSensorReadings';

type Props = {};

const Readings = (_props: Props) => {
  return (
    <div>
      <MockSensorReadings />
    </div>
  );
};

export default Readings;

import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit';
import { MockSensorReading } from '../../typings/SensorReading.model';
import { RootState } from '../store';

// * `mockStreamSensorReadings` demonstational component - to be removed later

const mockReadingAdapter = createEntityAdapter<MockSensorReading>({});

type SensorReadingsState = {
  mutationSensorReading: MockSensorReading | null;
  mockStreamSensorReadings: EntityState<MockSensorReading, string>;
};

const initialState: SensorReadingsState = {
  mutationSensorReading: null,
  mockStreamSensorReadings: mockReadingAdapter.getInitialState()
};

const sensorReadingsSlice = createSlice({
  name: 'sensorReadings',
  initialState,
  reducers: {
    setMutationSensorReading: (
      state: SensorReadingsState,
      action: PayloadAction<MockSensorReading | null>
    ) => {
      state.mutationSensorReading = action.payload;
    },
    addMockStreamSensorReadings: (
      state: SensorReadingsState,
      action: PayloadAction<MockSensorReading>
    ) => {
      mockReadingAdapter.addOne(state.mockStreamSensorReadings, action.payload);
    }
  }
});

export const sensorReadingsActions = {
  setMutationSensorReading:
    sensorReadingsSlice.actions.setMutationSensorReading,
  addMockSensorReadings: sensorReadingsSlice.actions.addMockStreamSensorReadings
};

export const sensorReadingsSelectors = {
  selectMutationSensorReading: (state: RootState) =>
    state.sensorReadings.mutationSensorReading,
  selectMockSensorReadings: (state: RootState) =>
    state.sensorReadings.mockStreamSensorReadings
};

export const sensorReadingsReducer = sensorReadingsSlice.reducer;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { environmentVariables } from '../../constants/environment';
import { BASE_API_PATH } from '../../constants/web';
import { MockSensorReading } from '../../typings/SensorReading.model';

export const sensorReadingsApi = createApi({
  reducerPath: 'sensorReadingApi',
  // tODO - switch to `baseCommClients.baseHttpQuery` after correcting typings
  baseQuery: fetchBaseQuery({
    baseUrl: `http://${environmentVariables.VITE_SERVER_HOSTNAME}${BASE_API_PATH}`
  }),
  tagTypes: ['MockSensorReading'],
  endpoints: (builder) => ({
    getMockSensorReadings: builder.query<MockSensorReading[], null>({
      query: () => ({ url: '/sensor-readings', method: 'GET' }),
      providesTags: ['MockSensorReading']
    }),
    createMockSensorReading: builder.mutation<MockSensorReading[], null>({
      query: () => ({ url: '/sensor-readings', method: 'POST', body: {} }),
      invalidatesTags: ['MockSensorReading']
    })
  })
});

export const {
  useGetMockSensorReadingsQuery,
  useCreateMockSensorReadingMutation
} = sensorReadingsApi;

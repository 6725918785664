import { useCallback, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

type Props<K, T> = {
  properties: K;
  websocketUrlPath: string;
  serverHostname: string;
  handleIncomingMessageCallback: (message: T) => void;
};

const WebsocketProvider = <K, T>({
  properties,
  websocketUrlPath,
  serverHostname,
  handleIncomingMessageCallback
}: Props<K, T>) => {
  // TODO - later, correct protocol to secure one
  const SOCKET_URL = `ws://${serverHostname}/${websocketUrlPath}`;

  const [_messages, setMessages] = useState<T[]>([]);

  // TODO - add websocket connection `close` on component unmount
  const {
    sendMessage,
    lastMessage: messageEvent,
    readyState
  } = useWebSocket(SOCKET_URL);

  const handleSendOutgoingMessage = useCallback(
    (properties: K) => {
      if (readyState === ReadyState.OPEN) {
        const msg = JSON.stringify(properties);
        // TODO - remove console after some testing with proper WS endpoints
        console.log('WebsocketProvider - handle outgoing message', msg);
        sendMessage(msg);
      } else {
        // TODO - remove console after some testing with proper WS endpoints
        console.log('WebsocketProvider - handle outgoing message', 'not ready');
      }
    },
    [readyState, sendMessage]
  );

  useEffect(() => {
    handleSendOutgoingMessage(properties);
  }, [properties, handleSendOutgoingMessage]);

  const handleIncomingMessage = useCallback(
    (messageEvent: MessageEvent<any> | null) => {
      if (messageEvent && messageEvent.data) {
        const messageData: T = JSON.parse(messageEvent.data);
        // TODO - remove console after some testing with proper WS endpoints
        console.log('WebsocketProvider - handle incoming message', messageData);
        setMessages((prevMessages) => [...prevMessages, messageData]);
        handleIncomingMessageCallback(messageData);
      }
    },
    [handleIncomingMessageCallback]
  );

  useEffect(() => {
    handleIncomingMessage(messageEvent);
  }, [messageEvent, handleIncomingMessage]);

  return <></>;
};

export default WebsocketProvider;

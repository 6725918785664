import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { environmentVariables } from '../../constants/environment';
import { BASE_API_PATH } from '../../constants/web';
import { AuthService } from '../../services/auth.service';
import { LoginRequest, SupabaseLoginResult } from '../../typings/User.model';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  // tODO - switch to `baseCommClients.baseHttpQuery` after correcting typings
  // tODO - we are using clients only, do we need this `baseQuery`?
  baseQuery: fetchBaseQuery({
    baseUrl: `http://${environmentVariables.VITE_SERVER_HOSTNAME}${BASE_API_PATH}`
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    loginUser: builder.mutation<SupabaseLoginResult, LoginRequest>({
      // TODO - also - investigate session for potential invalidation of old users
      // TODO - handle errors
      queryFn: async ({ user, password }, _queryApi, _extraOptions) => {
        console.log('login start', user, password);
        const authService: AuthService = new AuthService();
        const loginResult = await authService.login(user, password);
        console.log('login end', loginResult);
        return { data: loginResult };
      },
      invalidatesTags: ['User']
    })
  })
});

export const { useLoginUserMutation } = usersApi;

import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import './App.css';
import Header from './components/Header/Header';
import { environmentVariables } from './constants/environment';
import { RouteNames } from './constants/routes';
import Admin from './pages/admin/Admin';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import NotFound from './pages/not-found/NotFound';
import Readings from './pages/sensor-readings/Readings';

function App() {
  useEffect(() => {
    if (environmentVariables.MODE !== 'development') {
      console.log('env vars', environmentVariables);
    }
  }, []);

  return (
    <div className="w-full h-full">
      <Header className="z-40" />

      <div className="relative z-20">
        <div className="z-30 w-full h-full flex justify-center">
          <div className="content-wrapper flex justify-center border-rose-700 border-2 p-5 mt-32">
            <Routes>
              <Route path={RouteNames.HOME} element={<Dashboard />} />
              <Route path={RouteNames.LOGIN} element={<Login />} />
              <Route path={RouteNames.READINGS} element={<Readings />} />
              <Route path={RouteNames.ADMIN} element={<Admin />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>

      {/* TODO - add sticky footer */}

      {/* TODO - display BG */}
      <div className="z-10 absolute top-0 w-full h-full bg-gradient-to-r from-yellow-950 via-black to-rose-950" />
    </div>
  );
}

export default App;

// TODO - consider adding dark and light mode via `prefers-color-scheme` - https://tailwindcss.com/docs/hover-focus-and-other-states#prefers-color-scheme / https://tailwindcss.com/docs/dark-mode
// TODO - consider adding forced colors via `forced-colors` - https://tailwindcss.com/docs/hover-focus-and-other-states#forced-colors-mode
// TODO - consider adding reduced motion via `prefers-reduced-motion` - https://tailwindcss.com/docs/hover-focus-and-other-states#prefers-reduced-motion

// TODO - implement arias for all interactive DOM elements

// TODO - Readme - add ENV info
// TODO - Readme - add project desc section

// TODO - attach formatter
// TODO - consider pretties
// TODO - verify all tsconfig settings

/*
  ! Arco colors
    - you can pretty much only use couple of colors from Arco's color pallette
      - `primary`, `secondary, `warning`, `danger`, `success` and `info` colors
    - color pallete from `https://arco.design/react/en-US/docs/palette` is just a reference to precoded but unused color variables
    - all generics can be overwritten via `arco_design_overwrites.css`
    - we can try to wrap Arco's components with some custom class and attach all the TW colors via `styles` (but mind the focus and hover states)
    - this is a trap from official docs - https://github.com/arco-design/arco-plugins/blob/main/packages/plugin-vite-react/README.md - doesnt work
*/

// TODO - populate GH env variables with example values
// TODO - replicate similar deploy to GH pages for dev purposes - https://blog.logrocket.com/deploying-react-apps-github-pages/

// TODO - add toaster for displaying errors
// TODO - importing SVGs resolves as error but works - correct that

// TODO - add generic headings styling

// TODO - typings for RTK query client for axios - sth is wrong in `baseClient.ts`

// TODO - HERE
// TODO - add RTK integration to auth service
// TODO - add user.api.ts with promises to fetch user data
// TODO - add user data slice to redux store
// TODO - create login section
// TODO - add user slice or handle it other way
// TODO - handle session token refreshes

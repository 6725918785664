import { environmentVariables } from './environment';

export const RouteNames = {
  HOME: `${environmentVariables.VITE_BASE_PATH}`,
  READINGS: `${environmentVariables.VITE_BASE_PATH}readings`,
  ADMIN: `${environmentVariables.VITE_BASE_PATH}admin`,
  LOGIN: `${environmentVariables.VITE_BASE_PATH}login`
} satisfies Readonly<Record<string, string>>;

export const routeLabels = {
  [RouteNames.READINGS]: 'Readings',
  [RouteNames.ADMIN]: 'Admin',
  [RouteNames.LOGIN]: 'Login'
};
